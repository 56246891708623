import { Link } from "@remix-run/react"
import { Logo } from "~/modules/common/component/Logo"

export const Footer = () => {
  return (
    <footer className="flex flex-col justify-center gap-4 items-center min-h-[300px] bg-background">
      <Logo />
      <Link to="/privacy-policy" viewTransition>
        Privacy Policy
      </Link>
      <span>© Copyright Trooper {new Date().getFullYear()}</span>
    </footer>
  )
}
