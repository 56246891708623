import { type LoaderFunctionArgs, json } from "@remix-run/node"
import { Outlet, useLoaderData, useMatches } from "@remix-run/react"
import { useEffect } from "react"
import { Toaster, toast } from "sonner"
import { commitSession, getSession } from "~/modules/auth/api/session.server"
import type { RouteHandle } from "~/modules/common/type/RouteHandle"
import { Footer } from "~/modules/layout/component/Footer"
import { NavBar } from "../modules/layout/component/NavBar"

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get("Cookie"))
  const toastMessage = session.get("toast")

  return json(
    { toastMessage },
    {
      headers: {
        "Set-Cookie": await commitSession(session), // You must commit the session whenever you read a flash
      },
    },
  )
}

export default function LayoutOutlet() {
  const data = useLoaderData<typeof loader>()
  const matches = useMatches()
  const currentMatch = matches[matches.length - 1]
  const currentHandle = currentMatch.handle as RouteHandle

  const showFooter = currentHandle?.layout?.showFooter

  /**
   * Side Effect:
   * Read toast message flashed from loader
   */
  useEffect(() => {
    if (!data.toastMessage) return
    const { description, ...toastOptions } = data.toastMessage
    if (!toastOptions) return
    toast(String(description), toastOptions)
  }, [data.toastMessage])

  return (
    <div className="h-screen w-screen flex flex-col bg-background">
      <Toaster />
      <NavBar />
      <div className="h-full flex flex-grow">
        <Outlet />
      </div>
      {showFooter && <Footer />}
    </div>
  )
}
